import { useEffect } from 'react';
import Router from './Router';
import './styles/App.css';

function App() {
  useEffect(() => {
    document.querySelector('html').setAttribute('data-theme', 'light');
  }, []);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
