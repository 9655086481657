import { useCallback, useContext, useEffect } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';
import ConfigContext from '../../context/configContext';
import { useState } from 'react';
import Table from '../ui/Table';
import Modal from '../../utils/Modal';
import DwsUsernameManagement from './DWS/DWSUsernameManagement';
import DWSSimBars from './DWS/DWSSimBars';
import { XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

export default function Telephones({ siteID }) {
  const { getTelephones, data: telephones } = usePRDWebServices();
  const { isAuthenticated } = useAuth0();
  const { displayActiveNumbers, useDWSIntegration, showUnbilled } =
    useContext(ConfigContext);
  const [columns, setColumns] = useState([]);
  const [showDetails, setShowDetails] = useState('');
  const [showMobileBars, setShowMobileBars] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      getTelephones(displayActiveNumbers ? 1 : 0, siteID);
    }
  }, [isAuthenticated, displayActiveNumbers, siteID, getTelephones]);

  const setTableColumns = useCallback(() => {
    let tableColumns = [];
    tableColumns = [
      {
        Header: () => <div>Telephone</div>,
        id: 'telephonenumber',
        accessor: 'telephonenumber',
        Cell: ({ row }) =>
          siteID ? (
            <div>{row.original.telephonenumber}</div>
          ) : showUnbilled ? (
            <Link to={`/Usage?search=${row.original.telephonenumber}`}>
              {row.original.telephonenumber}
            </Link>
          ) : (
            <div>{row.original.telephonenumber}</div>
          )
      }
    ];
    if (useDWSIntegration && !siteID) {
      tableColumns.push({
        Header: () => <div>Details</div>,
        id: 'details',
        Cell: ({ row }) =>
          row.original.telephonenumber.startsWith('07') && (
            <div
              className='border p-0.5 rounded-lg text-xs border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 cursor-pointer'
              onClick={() => setShowDetails(row.original)}
            >
              Details
            </div>
          )
      });
    }
    if (useDWSIntegration && !siteID) {
      tableColumns.push({
        Header: () => <div>Mobile Bars</div>,
        id: 'mobileBars',
        Cell: ({ row }) =>
          row.original.telephonenumber.startsWith('07') && (
            <div
              className='border p-0.5 rounded-lg text-xs border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 cursor-pointer'
              onClick={() => setShowMobileBars(row.original.telephonenumber)}
            >
              Mobile Bars
            </div>
          )
      });
    }
    setColumns(tableColumns);
  }, [showUnbilled, siteID, useDWSIntegration]);

  useEffect(() => {
    setTableColumns();
  }, [siteID, setTableColumns]);

  return (
    <>
      {showDetails !== '' && (
        <Modal
          headerText={showDetails.telephonenumber}
          close={() => setShowDetails('')}
        >
          <DwsUsernameManagement
            telephone={showDetails.telephonenumber}
            telephoneID={showDetails.rowid}
          />
        </Modal>
      )}
      <div className='flex gap-4 lg:flex-row flex-col'>
        <div>
          {telephones && (
            <Table columns={columns} data={telephones} showSearch />
          )}
        </div>
        {showMobileBars && (
          <>
            <div>
              <DWSSimBars mobile={showMobileBars} />
            </div>
            <div
              className='cursor-pointer'
              onClick={() => setShowMobileBars('')}
            >
              <XIcon className='h-7 w-7' />
            </div>
          </>
        )}
      </div>
    </>
  );
}
