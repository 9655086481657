import React from 'react';

const Modal = ({ children, headerText, close, ok }) => {
  const addPropsToReactElement = (element, props) => {
    if (React.isValidElement(element)) {
      return React.cloneElement(element, props);
    }
    return element;
  };

  const addPropsToChildren = (children, props) => {
    if (!Array.isArray(children)) {
      return addPropsToReactElement(children, props);
    }
    return children.map((childElement) =>
      addPropsToReactElement(childElement, props)
    );
  };

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl bg-white rounded-xl'>
          <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
            <h3 className='text-3xl font-semibold'>{headerText}</h3>
          </div>
          <div className='relative p-6 flex-auto'>
            <div className='my-4 text-slate-500 text-lg leading-relaxed'>
              {addPropsToChildren(children, { close })}
            </div>
          </div>

          {close && (
            <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                type='button'
                onClick={() => close()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='opacity-25 fixed z-40 -inset-5 bg-black'></div>
    </>
  );
};

export default Modal;
