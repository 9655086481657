import { useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { Link } from 'react-router-dom';
import InvoiceDirectDownloads from './InvoiceDirectDownloads';
import InvoiceDownloads from './InvoiceDownloads';
import getSymbolFromCurrency from 'currency-symbol-map';
import ConfigContext from '../../context/configContext';

export default function InvoiceSummary({
  invoiceID,
  showDownload,
  showView,
  showResellerDownloads,
  siteID
}) {
  const { getMaxInvoiceNumber, data: maxInvoiceNumber } = usePRDWebServices();
  const { getInvoice, data: invoice } = usePRDWebServices();
  const { getTransactionSummary, data: transactionSummary } =
    usePRDWebServices();
  const { customerDetails } = useContext(ConfigContext);
  const { getSite, data: site } = usePRDWebServices();
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (maxInvoiceNumber) {
      getInvoice(maxInvoiceNumber, siteID);
      getTransactionSummary(maxInvoiceNumber, false, 'langen', siteID);
    }
    if (siteID) {
      getSite(siteID);
    }
  }, [maxInvoiceNumber, siteID, getInvoice, getTransactionSummary, getSite]);

  useEffect(() => {
    if (customerDetails?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(customerDetails.currency));
    }
    if (site) {
      setCurrencySymbol(getSymbolFromCurrency(site.currency));
    }
  }, [site, customerDetails]);

  useEffect(() => {
    if (invoiceID) {
      getInvoice(invoiceID, siteID);
      getTransactionSummary(invoiceID, false, 'langen', siteID);
    } else {
      if (!maxInvoiceNumber) {
        getMaxInvoiceNumber(siteID);
      }
    }
  }, [
    invoiceID,
    maxInvoiceNumber,
    siteID,
    getInvoice,
    getMaxInvoiceNumber,
    getTransactionSummary
  ]);

  return (
    invoice &&
    transactionSummary && (
      <>
        <div className='flex flex-col md:flex-row md:space-x-4 justify-between border-2 border-prd-purple p-5 rounded-2xl mx-auto bg-white gap-5'>
          <div className='w-full'>
            <h6 className='text-3xl border-b-2 pb-2 border-prd-purple'>
              {invoiceID ? 'Invoice Summary ' : 'Latest Invoice '} (
              {invoice.providerInvoiceId})
            </h6>

            <div className='flex justify-between gap-5 flex-col md:flex-row'>
              <div className='flex flex-col gap-5 mt-2 text-xl '>
                <p className='font-bold'>
                  Bill Period{' '}
                  {new Date(invoice.periodStartD).toLocaleDateString()} -{' '}
                  {new Date(invoice.periodEndD).toLocaleDateString()}
                </p>

                <p className='font-bold'>
                  Payment Due:{' '}
                  {new Date(invoice.periodEndD).toLocaleDateString()}
                </p>
              </div>

              <div className='mt-2'>
                <p className='font-bold border-b-2 border-prd-purple mb-2'>
                  Call Summary
                </p>
                <div className='grid grid-cols-4 gap-x-2'>
                  {transactionSummary.map((t) => (
                    <div key={t.Calltype}>
                      <p className='col-span-2'>{t.type}</p>
                      <p>{t.callsD}</p>
                      <p>
                        {currencySymbol}
                        {t.TotalAmount}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className='flex flex-col'>
                <div className='grid grid-cols-2 gap-x-4 mt-2'>
                  <p className='font-bold border-b-2 mb-2 border-prd-purple'>
                    Total Amount
                  </p>
                  <p className='font-bold border-b-2 mb-2 border-prd-purple'>
                    {currencySymbol}
                    {invoice.totalAmountWithVAT.toFixed(2)}
                  </p>
                  <p>Service Charges</p>
                  <p>
                    {currencySymbol}
                    {invoice.productAmount.toFixed(2)}
                  </p>
                  <p>Call Charges</p>
                  <p>
                    {currencySymbol}
                    {invoice.trafficAmount.toFixed(2)}
                  </p>
                  <p>Tax</p>
                  <p>
                    {currencySymbol}
                    {invoice.localTaxAmount.toFixed(2)}
                  </p>
                </div>

                {showView && (
                  <Link
                    className='border mt-5 text-center p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200'
                    to={
                      siteID
                        ? `/Invoices/sites/${siteID}/${invoice.invoiceId}`
                        : `/Invoices/${invoice.invoiceId}`
                    }
                  >
                    View Details
                  </Link>
                )}
              </div>
            </div>
          </div>
          {showDownload && <InvoiceDownloads invoice={invoice} />}
        </div>

        <div className='text-center text-2xl my-5'>
          {showResellerDownloads && invoice && (
            <InvoiceDirectDownloads invoiceID={invoiceID} />
          )}
        </div>
      </>
    )
  );
}
