import ConfigContext from '../context/configContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import OrgNotFound from './login/OrgNotFound';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import Login from './login/Login';

export const ProtectedRoute = ({ children, authReady }) => {
  const { orgInfo, testEnvironment } = useContext(ConfigContext);
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const appUrl = testEnvironment
    ? process.env.REACT_APP_APP_TESTURL
    : process.env.REACT_APP_APP_URL;

  if (location.pathname === '/' && !authReady) {
    return <Login />;
  }

  if (!orgInfo) {
    return <LoadingSpinner />;
  }

  if (orgInfo === 'Not Found') {
    return <OrgNotFound />;
  }

  if (!authReady) {
    loginWithRedirect({
      organization: orgInfo.OrgID,
      redirectUri: `https://${orgInfo.EUPURL}.${appUrl}/callback`
    });
  }
  return children;
};

export default ProtectedRoute;
