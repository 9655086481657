import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import ConfigContext from '../context/configContext';
import { useAuth0 } from '@auth0/auth0-react';

export const useDWSWebServices = () => {
  const { customerID, customerDetails, testEnvironment } =
    useContext(ConfigContext);
  const [data, setData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const apiUrl = testEnvironment
    ? process.env.REACT_APP_DWS_WEBSERVICE_URL
    : process.env.REACT_APP_DWS_WEBSERVICE_URL;


  const headers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return { Authorization: 'Bearer ' + token };
  }, [getAccessTokenSilently]);

  const getData = useCallback(
    async (urlParams) => {
      const authHeader = await headers();

      axios({
        url: apiUrl + urlParams,
        method: 'GET',
        params: {
          customerid: customerID
        },
        headers: authHeader
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setData(err.response.data);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  const putData = useCallback(
    async (urlParams, data) => {
      const authHeader = await headers();
      axios({
        url: apiUrl + urlParams,
        method: 'PUT',
        data: data,
        params: {
          customerid: customerID
        },
        headers: authHeader
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setData(err.response.data);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  const postData = useCallback(
    async (urlParams, data) => {
      const authHeader = await headers();
      axios({
        url: apiUrl + urlParams,
        method: 'POST',
        data: data,
        params: {
          customerid: customerID
        },
        headers: authHeader
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setData(err.response.data);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  const getUsername = useCallback(
    async (telephone) => {
      getData('Username/' + telephone);
    },
    [getData]
  );

  const setUsername = useCallback(
    async (telephone, data) => {
      putData('Username/' + telephone, data);
    },
    [putData]
  );

  const getSimSerial = useCallback(
    async (telephone) => {
      getData('SimSerial/' + telephone);
    },
    [getData]
  );

  const getNetwork = useCallback(
    async (telephone) => {
      getData('Network/' + telephone);
    },
    [getData]
  );

  const activateSim = useCallback(
    async (telephone, simSerial, network) => {
      const data = { simSerial: simSerial, simType: network };
      postData('ActivateSim/' + telephone, data);
    },
    [postData]
  );

  const getStolenBar = useCallback(
    async (telephone) => {
      getData('stolenBar/' + telephone);
    },
    [getData]
  );

  const setStolenBar = useCallback(
    async (telephone, bar, barName) => {
      const data = {
        bar: bar,
        barName: barName,
        customerReference: customerDetails.accountNumber
      };
      postData('stolenBar/' + telephone, data);
    },
    [postData, customerDetails.accountNumber]
  );

  const setBar = useCallback(
    async (telephone, bar, barName) => {
      const data = {
        bar: bar,
        barName: barName,
        customerReference: customerDetails.accountNumber
      };
      postData('bar/' + telephone, data);
    },
    [postData, customerDetails.accountNumber]
  );

  const getMobileBars = useCallback(
    async (telephone) => {
      getData('bars/' + telephone);
    },
    [getData]
  );

  const getMobileStatus = useCallback(
    async (telephone) => {
      getData('status/' + telephone);
    },
    [getData]
  );

  return {
    data,
    getUsername,
    setUsername,
    getSimSerial,
    getNetwork,
    activateSim,
    getStolenBar,
    setStolenBar,
    setBar,
    getMobileBars,
    getMobileStatus
  };
};
