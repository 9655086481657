import { useContext, useEffect, useState } from 'react';
import ConfigContext from '../../context/configContext';
import { UserCircleIcon } from '@heroicons/react/solid';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import Modal from '../../utils/Modal';
import { Link } from 'react-router-dom';

const Signup = () => {
  const { orgInfo } = useContext(ConfigContext);
  const [email, setEmail] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const { CheckAndInvite, data: inviteResponse } = usePRDWebServices();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Set the theme for the organization (prior to Auth0)
  useEffect(() => {
    if (orgInfo && orgInfo !== 'Not Found') {
      document.body.classList.add('default');
      document.body.classList.add(orgInfo.Theme.toLowerCase());
    }
  }, [orgInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const details = {
      email: email,
      accountNumber: accountNumber,
      orgID: orgInfo.OrgID
    };
    CheckAndInvite(details);
  };

  useEffect(() => {
    if (inviteResponse) {
      setIsLoading(false);
      if (inviteResponse?.status === 'error') {
        if (inviteResponse.message === 'Email Address already has access') {
          setError(
            'This email address already has access, please try logging in using your username and password. If you have forgotten your password select the Forgot Password option on the login screen'
          );
        } else {
          setError(inviteResponse.message);
        }
      }
      if (inviteResponse?.status === 'success') {
        setSuccess('Please check your email for an invitation');
      }
    }
  }, [inviteResponse]);

  return (
    <div className='login h-screen'>
      <div className='flex h-screen items-center justify-center'>
        {isLoading && (
          <Modal headerText='Please Wait'>
            <div className='w-96'>
              <LoadingSpinner />
            </div>
          </Modal>
        )}
        {orgInfo?.EnableSelfSignup === 'true' && (
          <div className='border-2  border-black rounded-2xl p-5 bg-white shadow-2xl'>
            <div className='flex flex-col md:flex-row md:gap-x-5 gap-y-5  items-center'>
              <div className='md:border-r-2 px-3 max-w-xs'>
                <div className='flex items-center mb-5'>
                  <UserCircleIcon className='w-10 h-10' />
                  <h1 className='font-bold text-center mb-2 flex-1'>Sign Up</h1>
                </div>
                <p className='mb-2'>
                  Please enter your registered email and account number to sign
                  up for an account
                </p>
                <p>
                  You will recieve an invitation in your email account which
                  will allow you access to the billing portal
                </p>
              </div>
              <form className=' w-80' onSubmit={handleSubmit}>
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                  id='email'
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                <label
                  className='block text-gray-700 text-sm font-bold mb-2'
                  htmlFor='accountnumber'
                >
                  Account Number
                </label>
                <input
                  className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-5 leading-tight focus:outline-none focus:shadow-outline'
                  id='accountnumber'
                  placeholder='Account Number'
                  onChange={(e) => setAccountNumber(e.target.value)}
                  value={accountNumber}
                  required
                />

                <button
                  className=' m-auto w-full rounded-full bg-prd-purple text-button-text-color hover:text-button-text-alternate-color  hover:bg-prd-md-purple p-3 font-bold  duration-200'
                  type='submit'
                  disabled={isLoading}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
        {orgInfo?.EnableSelfSignup === 'false' && (
          <div className='border-2  border-black rounded-2xl p-5 bg-white shadow-2xl'>
            Self Signup is not available for this portal
          </div>
        )}
      </div>
      {error && (
        <Modal headerText='Error Occured' close={() => setError(null)}>
          <div>{error}</div>
        </Modal>
      )}
      {success && (
        <Modal headerText='Invitation Sent'>
          <div className='flex flex-col gap-5 items-end'>
            <div>{success}</div>
            <div>
              <Link
                to='/'
                className='m-auto w-full rounded-full bg-prd-purple text-button-text-color hover:text-button-text-alternate-color  hover:bg-prd-md-purple p-3 font-bold  duration-200'
              >
                Home Page
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Signup;
