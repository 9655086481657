import { useEffect, useState } from 'react';
import Modal from '../../../../utils/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useDWSWebServices } from '../../../../hooks/useDWSWebServices';
import LoadingSpinner from '../../../ui/LoadingSpinner';

const DWSBar = ({ bar, close, mobile }) => {
  const [checked, setChecked] = useState(bar.value === '1');
  const barValue = bar.value === '1' ? true : false;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { setBar, data: setBarResult } = useDWSWebServices();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    console.log('Handle click', bar, checked);
    // Bring up dialog to confirm
    setShowConfirmation(true);
  };

  const handleOk = () => {
    setBar(mobile, checked, bar.name);
    setLoading(true);
    console.log('Bar Confirmed');
  };

  useEffect(() => {
    if (setBarResult) {
      console.log(setBarResult);
      setLoading(false);
    }
  }, [setBarResult]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (setBarResult !== null) {
    if (setBarResult.status !== '0') {
      return (
        <div>
          <h1 className='font-bold'>An Error Occurred</h1>
          <p>Status Code: {setBarResult.status}</p>
          <p>Error: {setBarResult.error.errorText}</p>
        </div>
      );
    } else {
      return (
        <div>
          {checked ? (
            <h1 className='font-bold'>Bar Add Requested</h1>
          ) : (
            <h1 className='font-bold'>Bar Removal Requested</h1>
          )}
          <p>Status Code: {setBarResult.caseNumber}</p>
          <p className='text-sm mt-3'>
            NOTE: it may take a up to 24 hours for the request to be made active
            and shown here
          </p>
        </div>
      );
    }
  }

  return (
    <>
      <div className='flex flex-col justify-center text-center'>
        <div className='flex items-center justify-center w-full mb-12'>
          <label
            htmlFor='stolenToggle'
            className='flex items-center cursor-pointer'
          >
            <div className='relative'>
              <input
                type='checkbox'
                checked={checked}
                onChange={() => setChecked(!checked)}
                id='stolenToggle'
                className='sr-only'
              />
              <div className='block bg-gray-600 w-14 h-8 rounded-full'></div>
              <div className='dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition'></div>
            </div>

            <div className='ml-3 text-gray-700 font-medium'>{bar.label}</div>
          </label>
        </div>
        <button
          type='button'
          className={`border mt-3 p-2 rounded-xl border-gray-500 w-28 m-auto ${
            checked === barValue
              ? 'bg-gray-400'
              : 'bg-prd-purple hover:bg-prd-md-purple'
          } text-white  duration-200`}
          onClick={handleClick}
          disabled={checked === barValue ? 'disable' : ''}
        >
          Save
        </button>
      </div>
      {showConfirmation && (
        <Modal
          ok={handleOk}
          close={() => setShowConfirmation(false)}
          headerText={bar.label}
        >
          <div className='flex flex-col justify-center align-middle text-center'>
            <p className='text-md '>{`Are you sure you want ${
              barValue ? `remove` : `set`
            } this bar for ${mobile}?`}</p>
            <div className='flex flex-row border-red-600 border-2 rounded-xl my-4 py-4 justify-center items-center '>
              <ExclamationIcon
                height={50}
                width={50}
                className='text-red-600 pl-3'
              />
              <p className='text-sm flex-grow w-11 pr-3'>
                You are responsible for any additional charges that are incurred
                by changing adding or removing this bar
              </p>
            </div>
            <button
              type='button'
              className={`border mt-3 p-2 rounded-xl border-gray-500 w-28 m-auto bg-prd-purple hover:bg-prd-md-purple text-white duration-200`}
              onClick={handleOk}
            >
              OK
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DWSBar;
