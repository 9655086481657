import jwtDecode from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';
import { usePRDWebServices } from '../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';

const ConfigContext = createContext({});

export const ConfigContextProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { getEUPSettings, data: settings } = usePRDWebServices();
  const { getCustomerDetails, data: customerDetails } = usePRDWebServices();
  const { getOrgInfo, data: orgInfo } = usePRDWebServices();

  const [showSettings, setShowSettings] = useState(null);
  const [showContactUs, setShowContactUs] = useState(null);
  const [displayActiveNumbers, setDisplayActiveNumbers] = useState(null);
  const [showUnbilled, setShowUnbilled] = useState(null);
  const [useSDRFile, setUseSDRFile] = useState(null);
  const [useCDRFile, setUseCDRFile] = useState(null);
  const [CDRExtensionOverride, setCDRExtensionOverride] = useState(null);
  const [SDRExtensionOverride, setSDRExtensionOverride] = useState(null);
  const [showResellerDownloads, setShowResellerDownloads] = useState(null);
  const [showDownloadRateCard, setShowDownloadRateCard] = useState(null);
  const [useDWSIntegration, setUseDWSIntegration] = useState(null);
  const [showBInvoiceDownload, setShowBInvoiceDownload] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [testEnvironment, setTestEnvironment] = useState(false);

  useEffect(() => {
    if (window.location.href.split('/')[2].split('.')[1] === 'test') {
      setTestEnvironment(true);
    }
    const orgName = window.location.href.split('/')[2].split('.')[0];
    getOrgInfo(orgName);
  }, [getOrgInfo]);

  //TODO - there was a problem (maybe timing) where I couldn't use the token from
  //this state to call the settings - as a work around I put the token into the
  //parameters here
  useEffect(() => {
    if (isAuthenticated) {
      getEUPSettings();
      getCustomerDetails(customerID);
    }
  }, [isAuthenticated, customerID, getEUPSettings, getCustomerDetails]);

  // Reverse sets it true if found - this is useful for reversed config items like
  // hideSettings (when you really want showSettings)
  const findSetting = useCallback(
    (fieldName, setFunction, reversed = false) => {
      const setting = settings.find(
        (setting) => setting.FieldName === fieldName
      );
      if (setting) {
        if (reversed) {
          setting.ShowRecord === 1 ? setFunction(false) : setFunction(true);
        } else {
          setting.ShowRecord === 1 ? setFunction(true) : setFunction(false);
        }
      } else {
        setFunction(false);
      }
    },
    [settings]
  );

  const getFeldCode = useCallback(
    (fieldName, setFunction) => {
      const setting = settings.find(
        (setting) => setting.FieldName === fieldName
      );
      if (setting) {
        setFunction(setting.FieldCode);
      }
    },
    [settings]
  );

  useEffect(() => {
    if (isAuthenticated && settings) {
      findSetting('ShowContactUs', setShowContactUs);
      findSetting('hideSettings', setShowSettings, true);
      findSetting('EUPDisplayActiveNumbersOnly', setDisplayActiveNumbers);
      findSetting('showUnbilled', setShowUnbilled);
      findSetting('useSDRFile', setUseSDRFile);
      findSetting('useCDRFile', setUseCDRFile);
      findSetting('ShowResellerDownloads', setShowResellerDownloads);
      findSetting('ShowDownloadRateCard', setShowDownloadRateCard);
      findSetting('EnableDWSIntegration', setUseDWSIntegration);
      findSetting('ShowBInvoiceDownload', setShowBInvoiceDownload);

      getFeldCode('useSDRFile', setSDRExtensionOverride);
      getFeldCode('useCDRFile', setCDRExtensionOverride);
    }
  }, [isAuthenticated, settings, findSetting, getFeldCode]);

  const numAuthorisedCustomers = useCallback(async () => {
    const decodedToken = jwtDecode(await getAccessTokenSilently());

    if (Array.isArray(decodedToken['https://p-rd.com/claims CustomerID'])) {
      return decodedToken['https://p-rd.com/claims CustomerID'].length;
    } else {
      return 1;
    }
  }, [getAccessTokenSilently]);

  const context = {
    showContactUs,
    showSettings,
    displayActiveNumbers,
    showUnbilled,
    customerDetails,
    useCDRFile,
    useSDRFile,
    CDRExtensionOverride,
    SDRExtensionOverride,
    showResellerDownloads,
    showDownloadRateCard,
    orgInfo,
    useDWSIntegration,
    showBInvoiceDownload,
    customerID,
    setCustomerID,
    numAuthorisedCustomers,
    testEnvironment
  };

  return (
    <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
  );
};

export default ConfigContext;
