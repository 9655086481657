import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../../context/configContext';
import { usePRDWebServices } from '../../../hooks/usePRDWebServices';

const Menu = () => {
  const { showUnbilled, showContactUs, showSettings } =
    useContext(ConfigContext);
  const { getSites, data: sites } = usePRDWebServices();

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <>
      <li>
        <Link underline='none' color='inherit' to={'/'}>
          <h6>Home</h6>
        </Link>
      </li>
      <li>
        <Link underline='none' color='inherit' to={'/Invoices'}>
          <h6>Invoices</h6>
        </Link>
      </li>
      {sites?.length > 0 ? (
        <li>
          <Link underline='none' color='inherit' to={'/Sites'}>
            <h6>Sites</h6>
          </Link>
        </li>
      ) : null}
      <li>
        <Link underline='none' color='inherit' to={'/Products'}>
          <h6>Telephones</h6>
        </Link>
      </li>
      <li>
        <Link underline='none' color='inherit' to={'/Services'}>
          <h6>Services</h6>
        </Link>
      </li>
      {showUnbilled && (
        <li>
          <Link underline='none' color='inherit' to={'/Usage'}>
            <h6>Usage</h6>
          </Link>
        </li>
      )}
      {showContactUs && showContactUs !== false ? (
        <li>
          <Link underline='none' to={'/contact'} color='inherit'>
            <h6>Contact Us</h6>
          </Link>
        </li>
      ) : null}
      {showSettings && showSettings !== false ? (
        <li>
          <Link underline='none' to={'/settings'} color='inherit'>
            <h6>Settings</h6>
          </Link>
        </li>
      ) : null}
    </>
  );
};

export default Menu;
