import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ConfigContextProvider } from './context/configContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{ audience: audience }}
        useCookiesForTransactions={true}
        useRefreshTokens={true}
      >
        <ConfigContextProvider>
          <App />
        </ConfigContextProvider>
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);
