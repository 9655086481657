import { useEffect, useState } from "react";
import { useDWSWebServices } from "../../../hooks/useDWSWebServices";
import LoadingSpinner from "../../ui/LoadingSpinner";
import DWSMobileBars from "./DWSStolenBar";
import DWSSimSwap from "./DWSSimSwap";
import DWSUsernameManagementChange from "./DWSUsernameManagementChange";

const DWSUsernameManagement = ({ telephone, telephoneID }) => {
  const { getUsername, data: username } = useDWSWebServices();
  const { getSimSerial, data: simSerial } = useDWSWebServices();
  const { getMobileStatus, data: mobileStatus } = useDWSWebServices();
  const { getNetwork, data: network } = useDWSWebServices();
  const [changeDetails, setChangeDetails] = useState(false);
  const [simSwap, setSimSwap] = useState(false);
  const [mobileBars, setMobileBars] = useState(false);

  useEffect(() => {
    getUsername(telephone);
    getNetwork(telephone);
    getSimSerial(telephone);
    getMobileStatus(telephone);
  }, [telephone, getUsername, getNetwork, getSimSerial, getMobileStatus]);

  if (!username) {
    return <LoadingSpinner className='w-96' />;
  }

  if (username?.status !== "0") {
    return (
      <div>
        <h1 className='font-bold'>An Error Occurred</h1>
        <p>Status Code: {username.status}</p>
        <p>Error: {username.error.errorText}</p>
      </div>
    );
  }

  if (changeDetails) {
    return (
      <DWSUsernameManagementChange
        username={username}
        mobile={telephone}
        telephoneID={telephoneID}
      />
    );
  }

  if (simSwap) {
    return <DWSSimSwap mobile={telephone} network={network.network} />;
  }

  if (mobileBars) {
    return <DWSMobileBars mobile={telephone} />;
  }

  return (
    <>
      {username && (
        <div className='flex flex-col justify-center items-center'>
          <table className='table-fixed w-full '>
            <tbody>
              <tr>
                <td className='font-semibold'>Username</td>
                <td className='font-semibold'>{username.username}</td>
              </tr>
              <tr>
                <td>Cost Centre 1</td>
                <td>{username.costCentre1}</td>
              </tr>
              <tr>
                <td>Cost Centre 2</td>
                <td>{username.costCentre2}</td>
              </tr>
              <tr>
                <td>Cost Centre 3</td>
                <td>{username.costCentre3}</td>
              </tr>
              <tr>
                <td>Sim Serial</td>
                <td>{simSerial ? simSerial?.simSerial : "Loading..."}</td>
              </tr>
              <tr>
                <td>Network</td>
                <td>{network ? network?.network : "Loading..."}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <div
                    className={`py-1 px-4 w-fit no-underline rounded-full text-white font-sans font-semibold text-sm ${
                      mobileStatus?.status === "active"
                        ? "bg-green-600"
                        : "bg-red-600"
                    }`}
                  >
                    {mobileStatus ? mobileStatus?.status : "Loading..."}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='flex gap-3'>
            <button
              type='button'
              className='border mt-3 p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'
              onClick={() => setChangeDetails(true)}
            >
              Change Details
            </button>
            <button
              type='button'
              className='border mt-3 p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'
              onClick={() => setSimSwap(true)}
            >
              Sim Swap
            </button>
            <button
              type='button'
              className='border mt-3 p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'
              onClick={() => setMobileBars(true)}
            >
              Stolen Bar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DWSUsernameManagement;
