import React, { useCallback, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../../context/configContext';
import { usePRDWebServices } from '../../../hooks/usePRDWebServices';
import Table, { TextLeft } from '../../ui/Table';

export default function Sites() {
  const { getSites, data: sites } = usePRDWebServices();
  const [columns, setColumns] = useState([]);
  const { customerDetails } = useContext(ConfigContext);

  useEffect(() => {
    if (!sites) {
      getSites();
    }
  }, [sites, getSites]);

  const setTableColumns = useCallback(() => {
    var tableColumns = [];

    tableColumns = [
      {
        Header: () => <div className='text-left'>Account Number</div>,
        accessor: 'Accountnumber',
        Cell: TextLeft
      },
      {
        Header: () => <div className='text-left'>Name</div>,
        accessor: 'Customername',
        Cell: TextLeft
      },
      // {
      //   Header: () => <div className='text-left'>Address 1</div>,
      //   accessor: 'addressline1',
      //   Cell: TextLeft
      // },
      // {
      //   Header: () => <div className='text-left'>Address 2</div>,
      //   accessor: 'addressline2',
      //   Cell: TextLeft
      // },
      // {
      //   Header: () => <div className='text-left'>Address 3</div>,
      //   accessor: 'addressline3',
      //   Cell: TextLeft
      // },
      {
        Header: () => <div className='text-left'>Post Code</div>,
        accessor: 'postcode',
        Cell: TextLeft
      }
    ];

    // Invoice shown if customerType is Reseller (1)
    // or siteBilling is Seperate [sic] billing per site
    if (
      customerDetails.customerType === 1 ||
      customerDetails.siteBilling === 1
    ) {
      tableColumns.push({
        Header: () => <div className='text-left'>Invoices</div>,
        id: 'invoiceLink',
        Cell: ({ row }) => (
          <Link
            style={{ display: 'block', margin: '1rem 0' }}
            to={`/invoices/sites/${row.original.Customerid}`}
          >
            <div className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'>
              Site Invoices
            </div>
          </Link>
        )
      });
    }

    tableColumns.push({
      Header: () => <div className='text-left'>Telephones</div>,
      id: 'productLink',
      Cell: ({ row }) => (
        <Link
          style={{ display: 'block', margin: '1rem 0' }}
          to={`/products/sites/${row.original.Customerid}`}
        >
          <div className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'>
            Site Telephones
          </div>
        </Link>
      )
    });
    tableColumns.push({
      Header: () => <div className='text-left'>Services</div>,
      id: 'serviceLink',
      Cell: ({ row }) => (
        <Link
          style={{ display: 'block', margin: '1rem 0' }}
          to={`/services/sites/${row.original.Customerid}`}
        >
          <div className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200'>
            Site Services
          </div>
        </Link>
      )
    });

    setColumns(tableColumns);
  }, [customerDetails]);

  useEffect(() => {
    setTableColumns();
  }, [setTableColumns]);

  return <>{sites && <Table columns={columns} data={sites} showSearch />}</>;
}
