import { useState } from 'react';
import { useEffect } from 'react';
import { useDWSWebServices } from '../../../hooks/useDWSWebServices';
import LoadingSpinner from '../../ui/LoadingSpinner';
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilIcon
} from '@heroicons/react/outline';
import Error from '../../ui/Error';
import Modal from '../../../utils/Modal';
import DWSBar from './DWSComponents/DWSBar';

const SimBar = ({ bar, editBar }) => {
  return (
    <div className='flex text-left gap-2 items-center'>
      <PencilIcon
        className='w-4 h-4 cursor-pointer'
        onClick={() => editBar(bar)}
      />
      {bar.value === '0' ? (
        <XCircleIcon className='text-red-600 h-7 w-7' />
      ) : (
        <CheckCircleIcon className=' text-green-600 h-7 w-7' />
      )}
      <div>{bar.label}</div>
    </div>
  );
};

const DWSSimBars = ({ mobile }) => {
  const { getMobileBars, data: bars } = useDWSWebServices();
  const [loading, setLoading] = useState(false);
  const [currentMobile, setCurrentMobile] = useState();
  const [currentBars, setCurrentBars] = useState();
  const [editBar, setEditBar] = useState();

  // Calls getMobileBars when when currentMobile changes
  useEffect(() => {
    setEditBar(null);
    if (currentMobile) {
      getMobileBars(currentMobile);
    }
  }, [currentMobile, getMobileBars]);

  // Sets the current bar when web service bars change
  // turns off loading spinner
  useEffect(() => {
    setCurrentBars(bars);
    setLoading(false);
  }, [bars]);

  // Sets the current mobile when mobile changes
  // turns on mobile spinner
  useEffect(() => {
    setLoading(true);
    setCurrentMobile(mobile);
  }, [mobile]);

  if (loading) {
    return (
      <>
        <h1 className='text-lg text-center'>Active Bars for {mobile}</h1>
        <div className='flex flex-col border-2 border-prd-purple p-5 rounded-lg shadow-xl mb-6 bg-white'>
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <>
      <h1 className='text-lg text-center'>Active Bars for {mobile}</h1>{' '}
      <div className='flex flex-col border-2 border-prd-purple p-5 rounded-lg shadow-xl mb-6 bg-white'>
        <div className='grid lg:grid-cols-2 gap-2 grid-cols-1'>
          {currentBars &&
            currentBars.mobileBars.map((bar) => (
              <SimBar bar={bar} key={bar.name} editBar={setEditBar} />
            ))}
        </div>
        {currentBars && currentBars?.status !== '0' && (
          <Error
            errorMsg={currentBars.error.errorText}
            errorDescription={currentBars.error.errorDescription}
          />
        )}
        {editBar && (
          <Modal headerText='Edit Mobile Bar' close={() => setEditBar(null)}>
            <DWSBar bar={editBar} mobile={mobile} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default DWSSimBars;
