import { useContext, useEffect } from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import ConfigContext from '../../context/configContext';
import { useNavigate } from 'react-router-dom';

const SelectAccount = () => {
  const { getAuthorisedCustomers, data: authorisedCustomers } =
    usePRDWebServices();
  const { customerID, setCustomerID } = useContext(ConfigContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAuthorisedCustomers();
  }, [getAuthorisedCustomers]);

  useEffect(() => {
    if (customerID !== null) {
      navigate('/');
    }
  }, [customerID, navigate]);

  return (
    <div className='login h-screen'>
      <div className='flex h-screen items-center justify-center'>
        <div className='flex flex-col border-2 p-5 border-prd-purple rounded-2xl bg-white shadow-2xl'>
          <div className=' font-bold text-2xl mb-3'>Select Account</div>
          {authorisedCustomers &&
            authorisedCustomers
              .sort((a, b) => a.customerName.localeCompare(b.customerName))
              .map((customer) => (
                <div
                  key={customer.rowId}
                  className='flex gap-3 p-2 items-center hover:bg-prd-lt-purple rounded-2xl cursor-pointer duration-200'
                  onClick={() => setCustomerID(customer.customerID)}
                >
                  <OfficeBuildingIcon className='w-5 h-5 mr-3' />
                  <div>{customer.customerName}</div>
                </div>
              ))}
          {!authorisedCustomers && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default SelectAccount;
