import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useContext } from 'react';
import ConfigContext from '../context/configContext';

// need to put in some validation and error handling

export const usePRDWebServices = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const { customerID, testEnvironment } = useContext(ConfigContext);
  const { getAccessTokenSilently } = useAuth0();

  const apiUrl = testEnvironment
    ? process.env.REACT_APP_TESTWEBSERVICE_URL
    : process.env.REACT_APP_WEBSERVICE_URL;

  const headers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return { Authorization: 'Bearer ' + token };
  }, [getAccessTokenSilently]);

  const GetFullURL = useCallback(
    (url, urlParams) => {
      if (urlParams) {
        if (customerID) {
          return url + '?' + urlParams + '&customerid=' + customerID;
        } else {
          return url + '?' + urlParams;
        }
      } else {
        if (customerID) {
          return url + '?customerid=' + customerID;
        } else {
          return url;
        }
      }
    },
    [customerID]
  );

  const getData = useCallback(
    async (serviceName, urlParams = null, webservice = 'EndUser/') => {
      const authHeader = await headers();
      axios({
        method: 'GET',
        url: GetFullURL(apiUrl + webservice + serviceName, urlParams),
        headers: authHeader
      })
        .then((res) => {
          setData(res.data.result);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [apiUrl, headers, GetFullURL]
  );

  const getDataNoAuth = useCallback(
    async (urlParams, webservice = 'EndUser/') => {
      axios
        .get(apiUrl + webservice + urlParams)
        .then((res) => {
          setData(res.data.result);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            //logout();
          } else if (err.response.status === 404) {
            setData('Not Found');
          }
        });
    },
    [apiUrl]
  );

  const putData = useCallback(
    async (urlParams, data) => {
      const authHeader = await headers();
      axios({
        method: 'PUT',
        url: apiUrl + urlParams,
        data: data,
        headers: authHeader
      })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setData(err.response.data);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [headers, apiUrl]
  );

  const postDataNoAuth = useCallback(
    async (urlParams, data, webservice = 'EndUser/') => {
      axios
        .post(apiUrl + webservice + urlParams, data)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setData(err.response.data);
          if (err.response.status === 401) {
            //logout();
          }
        });
    },
    [apiUrl]
  );

  const customFieldExists = async (fieldName, tableName, showRecord) => {
    const authHeader = await headers();
    axios({
      method: 'GET',
      url:
        apiUrl +
        'CustomFieldExists/' +
        fieldName +
        '?tablename=' +
        tableName +
        '&showrecord=' +
        showRecord,
      headers: authHeader
    })
      .then((res) => {
        if (res.data.result === 1) {
          setData(true);
        } else {
          setData(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          //logout();
        }
      });
  };

  const getEUPSettings = useCallback(
    async (theCustomerID) => {
      getData(
        'GetCustomFields',
        theCustomerID ? 'customerid=' + theCustomerID : ''
      );
    },
    [getData]
  );

  const getCustomerDetails = useCallback(
    async (theCustomerID) => {
      getData(
        'CustomerDetails',
        theCustomerID ? 'customerid=' + theCustomerID : ''
      );
    },
    [getData]
  );

  const getCurrentUser = useCallback(async () => {
    getData('GetCurrentUser');
  }, [getData]);

  const getMaxInvoiceNumber = useCallback(
    async (siteID) => {
      if (siteID) {
        getData('GetMaxInvoiceNumber', 'siteID=' + siteID, 'site/');
      } else {
        getData('GetMaxInvoiceNumber');
      }
    },
    [getData]
  );

  const getInvoice = useCallback(
    async (invoiceNum, siteID) => {
      if (siteID) {
        getData('GetSiteInvoice/' + siteID + '/' + invoiceNum, null, 'site/');
      } else {
        getData('GetInvoice/' + invoiceNum);
      }
    },
    [getData]
  );

  const getTelephones = useCallback(
    async (showActive = 1, siteID) => {
      if (siteID) {
        getData('Telephones/' + siteID, 'showActive=' + showActive, 'site/');
      } else {
        getData('Telephones', 'showActive=' + showActive);
      }
    },
    [getData]
  );

  const getProducts = useCallback(
    async (showActive = 1, siteID) => {
      if (siteID) {
        getData('Products/' + siteID, 'showActive=' + showActive, 'site/');
      } else {
        getData('Products', 'showActive=' + showActive);
      }
    },
    [getData]
  );

  const getUnbilled = useCallback(async () => {
    getData('Unbilled');
  }, [getData]);

  const getTopInvoices = useCallback(
    async (top = 6, siteID) => {
      if (siteID) {
        getData('GetSiteTopInvoices/' + siteID + '/' + top, null, 'site/');
      } else {
        getData('GetTopInvoices/' + top);
      }
    },
    [getData]
  );

  const getOrgInfo = useCallback(
    async (orgName) => {
      getDataNoAuth('GetOrgInfo/' + orgName);
    },
    [getDataNoAuth]
  );

  const getSites = useCallback(async () => {
    getData('GetSites', null, 'site/');
  }, [getData]);

  const getSite = useCallback(
    async (siteID) => {
      getData('GetSite/' + siteID, null, 'site/');
    },
    [getData]
  );

  const getAuthorisedCustomers = useCallback(async () => {
    getData('GetAuthorisedCustomers');
  }, [getData]);

  const getTransactionSummary = useCallback(
    async (invoiceId, showTelProductId, language, siteID) => {
      if (siteID) {
        getData(
          'getSiteTransactionSummary/' + siteID + '/' + invoiceId,
          'showTelProductId=' + showTelProductId + '&language=' + language,
          'site/'
        );
      } else {
        getData(
          'getTransactionSummary/' + invoiceId,
          'showTelProductId=' + showTelProductId + '&language=' + language
        );
      }
    },
    [getData]
  );

  const getCalls = useCallback(
    async (invoiceId, callType, handleDivert, siteID) => {
      if (siteID) {
        getData(
          'GetSiteCalls/' + siteID + '/' + invoiceId,
          'callType=' + callType + '&handleDivert=' + handleDivert,
          'site/'
        );
      } else {
        getData(
          'GetCalls/' + invoiceId,
          'callType=' + callType + '&handleDivert=' + handleDivert
        );
      }
    },
    [getData]
  );

  const getServices = useCallback(
    async (invoiceId, showTelProductID, siteID) => {
      if (siteID) {
        getData(
          'GetSiteServices/' + siteID + '/' + invoiceId,
          'showTelProductID=' + showTelProductID,
          'site/'
        );
      } else {
        getData(
          'GetServices/' + invoiceId,
          'showTelProductID=' + showTelProductID
        );
      }
    },
    [getData]
  );

  const getProvider = useCallback(async () => {
    getData('GetProvider');
  }, [getData]);

  const getCDRs = useCallback(
    async (invoiceId) => {
      getData('GetCDRs', 'invoiceId=' + invoiceId);
    },
    [getData]
  );

  const setTelephoneMetaData = useCallback(
    async (telephoneID, data) => {
      putData('UpdateTelephoneMetaData/' + telephoneID, data);
    },
    [putData]
  );

  const CheckAndInvite = useCallback(
    async (details) => {
      postDataNoAuth('CheckAndInviteUser', details);
    },
    [postDataNoAuth]
  );

  const downloadFile = useCallback(
    async (invoiceId, filetype, extensionOverride, bInvoice) => {
      const authHeader = await headers();
      axios({
        method: 'GET',
        url: apiUrl + 'EndUser/DownloadFile',
        headers: authHeader,
        responseType: 'blob',
        params: {
          invoiceId: invoiceId,
          filetype: filetype,
          ...(extensionOverride && { extensionOverride: extensionOverride }),
          ...(bInvoice && { bInvoice: true }),
          ...(customerID && { customerid: customerID })
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers['content-type'] })
          );
          const link = document.createElement('a');
          link.href = url;
          const file = res.headers['content-disposition'].substring(23, 52);
          link.setAttribute('download', file);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log(err.response);
          }
          if (err.response.status === 404) {
            err.response.data.text().then((resText) => {
              console.log(resText);
              setError(JSON.parse(resText).result);
              setErrorDate(Date());
            });
          } else {
            console.log(err.response);
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  const downloadZipFile = useCallback(
    async (invoiceId, filetype) => {
      const authHeader = await headers();
      axios({
        method: 'GET',
        url: apiUrl + 'EndUser/DownloadZipFile',
        headers: authHeader,
        responseType: 'blob',
        params: {
          invoiceId: invoiceId,
          filetype: filetype,
          ...(customerID && { customerid: customerID })
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers['content-type'] })
          );
          const link = document.createElement('a');
          link.href = url;
          let file = res.headers['content-disposition'];
          file = file.substring(file.lastIndexOf('=') + 1, file.length);
          link.setAttribute('download', file);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err.response);

          if (err.response.status === 401) {
            // logout();
            console.log(err);
          }
          if (err.response.status === 404) {
            err.response.data.text().then((resText) => {
              console.log(resText);
              setError(JSON.parse(resText).result);
              setErrorDate(Date());
            });
          }
          if (err.response.status === 400) {
            err.response.data.text().then((resText) => {
              console.log(resText);
              setError(JSON.parse(resText).result);
              setErrorDate(Date());
            });
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  const downloadRateZipFile = useCallback(
    async (invoiceId) => {
      const authHeader = await headers();
      axios({
        method: 'GET',
        url: apiUrl + 'DownloadRateCardZip',
        headers: authHeader,
        params: {
          invoiceId: invoiceId,
          ...(customerID && { customerid: customerID })
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers['content-type'] })
          );
          const link = document.createElement('a');
          link.href = url;
          let file = res.headers['content-disposition'];
          file = file.substring(file.lastIndexOf('=') + 1, file.length);
          link.setAttribute('download', file);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err.response);

          if (err.response.status === 401) {
            // logout();
            console.log(err);
          }
          if (err.response.status === 404) {
            err.response.data.text().then((resText) => {
              console.log(resText);
              setError(JSON.parse(resText).result);
              setErrorDate(Date());
            });
          }
          if (err.response.status === 400) {
            err.response.data.text().then((resText) => {
              console.log(resText);
              setError(JSON.parse(resText).result);
              setErrorDate(Date());
            });
          }
        });
    },
    [apiUrl, headers, customerID]
  );

  return {
    data,
    customFieldExists,
    getCurrentUser,
    getMaxInvoiceNumber,
    getInvoice,
    getTelephones,
    getProducts,
    getCustomerDetails,
    getTopInvoices,
    getUnbilled,
    getTransactionSummary,
    getCalls,
    getServices,
    getProvider,
    downloadFile,
    downloadZipFile,
    downloadRateZipFile,
    getEUPSettings,
    error,
    errorDate,
    getCDRs,
    getOrgInfo,
    setTelephoneMetaData,
    getSites,
    getSite,
    getAuthorisedCustomers,
    CheckAndInvite
  };
};
