import Sites from '../../../components/invoices/sites/Sites';

export default function MySiteInvoices() {
  return (
    <>
      <div>
        <h4 className='text-2xl text-center mb-5'>Sites</h4>

        <div className='flex flex-col justify-center gap-5'>
          <div className='lg:px-20'>
            <Sites />
          </div>
        </div>
      </div>
    </>
  );
}
