import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';

export default function PastInvoicesGraph({ siteID }) {
  const { isAuthenticated } = useAuth0();
  const { getTopInvoices, data: topInvoices } = usePRDWebServices();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (isAuthenticated && !topInvoices) {
      getTopInvoices(6, siteID);
    }
    if (topInvoices) {
      let chart = {
        options: {
          chart: {
            id: 'basic-bar',
            dropShadow: {
              enabled: true
            }
          },
          tooltip: {
            enabled: false
          },
          xaxis: {
            categories: topInvoices.map((invoice) =>
              new Date(invoice.dueDateD).toLocaleDateString()
            )
          },
          plotOptions: {
            bar: {
              borderRadius: 10
            }
          }
        }
      };

      chart = {
        ...chart,
        series: [
          {
            name: 'Invoice Amount',
            data: topInvoices.map((invoice) => invoice.totalAmountWithVAT)
          }
        ]
      };

      setChartData(chart);
    }
  }, [isAuthenticated, topInvoices, getTopInvoices, siteID]);

  return (
    <div className='border-2 border-prd-purple rounded-xl p-2 bg-white h-full'>
      {chartData && (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type='bar'
          width='500px'
        />
      )}
    </div>
  );
}
