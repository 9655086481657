import React, { useContext } from 'react';
import ConfigContext from '../../context/configContext';

const Footer = () => {
  const { orgInfo, testEnvironment } = useContext(ConfigContext);

  return (
    <footer className='bg-footer-color py-16 mt-auto'>
      <div className='container flex flex-col items-center justify-between mx-auto md:flex-row md:items-start space-y-16 md:space-y-0'>
        <img
          src={`../../images/logos/${orgInfo.EUPURL}-footer-logo.png`}
          className='object-scale-down h-20'
          alt='Footer Logo'
        />
        <div>
          <img
            src='../../images/ib_logo.png'
            className='object-scale-down h-20'
            alt='Intelligent Billing Logo'
          />
        </div>
      </div>
      <div className='text-center text-white'>
        <p>
          &copy; {new Date().getFullYear()} PRD Technologies Ltd (v
          {process.env.REACT_APP_VERSION})
        </p>
        <p></p>
      </div>
      {testEnvironment && (
        <div className='alert alert-warning shadow-lg w-1/2 m-auto mt-4 flex justify-center'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='stroke-current flex-shrink-0 h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
              />
            </svg>
            <span>PRE-RELEASE ENVIRONMENT</span>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
