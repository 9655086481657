import React from 'react';

const OrgNotFound = () => {
  return (
    <div className='no-org h-screen'>
      <div className='flex justify-center items-center h-full'>
        <p className='text-4xl font-bold'>Page Not Found</p>
      </div>
    </div>
  );
};

export default OrgNotFound;
