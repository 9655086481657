import React from 'react';
import Menu from './navBar/Menu';
import AccountMenu from './navBar/AccountMenu';

const Drawer = () => {
  return (
    <div className='drawer-side'>
      <label htmlFor='app-drawer' className='drawer-overlay'></label>
      <ul className='menu p-4 w-80 bg-base-100'>
        <AccountMenu />
        <Menu />
      </ul>
    </div>
  );
};

export default Drawer;
