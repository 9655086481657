export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function downloadCSVfromJSON(data, filename) {
  const keys = Object.keys(data[0]);

  const commaSeparatedString = [
    keys.join(','),
    data.map((row) => keys.map((key) => row[key]).join(',')).join('\n')
  ].join('\n');

  const url = window.URL.createObjectURL(new Blob([commaSeparatedString]), {
    type: 'application/vnd.ms-excel'
  });
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
}
