import { useContext } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import Login from './pages/login/Login';
import MyInvoices from './pages/myInvoices/MyInvoices';
import { SharedLayout } from './pages/SharedLayout';
import ProtectedRoute from './pages/ProtectedRoute';
import ConfigContext from './context/configContext';
import { useAuth0 } from '@auth0/auth0-react';
import Callback from './pages/login/Callback';
import Contact from './pages/contact/Contact';
import Homepage from './pages/homepage/Homepage';
import InvoiceDetail from './pages/myInvoices/InvoiceDetail';
import MyProducts from './pages/myProducts/MyProducts';
import Settings from './pages/settings/Settings';
import { Invitation } from './pages/login/Invitation';
import InvitationAccepted from './pages/login/InvitationAccepted';
import MySiteInvoices from './pages/myInvoices/sites/MySiteInvoices';
import SelectAccount from './pages/login/SelectAccount';
import { MyUsage } from './pages/myUsage/MyUsage';
import MyServices from './pages/myServices/MyServices';
import Signup from './pages/login/Signup';

export default function Router() {
  const { showContactUs, showSettings, showUnbilled } =
    useContext(ConfigContext);
  const { error, isAuthenticated } = useAuth0();
  const [params] = useSearchParams();

  if (error) {
    console.log(error);
    return <p>Error Occured: {JSON.stringify(error)}</p>;
  }

  return (
    <>
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute authReady={isAuthenticated}>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Homepage />} />
          <Route path='/invoices' element={<MyInvoices />} />
          <Route path='/invoices/:id' element={<InvoiceDetail />} />
          <Route path='/sites' element={<MySiteInvoices />} />

          {/* /myinvoices/sites/siteID - This will show the my invoices page for that site */}
          {/* /myinvoices/sites/siteID/invoiceID - this will show the invoice details for that site */}
          {/* This is for child sites and should be turned on or off depending on config setting */}
          <Route path='/invoices/sites/:siteId' element={<MyInvoices />} />
          <Route
            path='/invoices/sites/:siteId/:id'
            element={<InvoiceDetail />}
          />

          <Route path='/products' element={<MyProducts />} />
          <Route path='/products/sites/:siteId' element={<MyProducts />} />
          <Route path='/services' element={<MyServices />} />
          <Route path='/services/sites/:siteId' element={<MyServices />} />

          {showUnbilled && <Route path='/usage' element={<MyUsage />} />}
          {showUnbilled && (
            <Route path='/usage/:search' element={<MyUsage />} />
          )}
          {showContactUs && <Route path='/contact' element={<Contact />} />}
          {showSettings && <Route path='/settings' element={<Settings />} />}
        </Route>

        <Route
          path='/selectaccount'
          element={
            <ProtectedRoute authReady={isAuthenticated}>
              <SelectAccount />
            </ProtectedRoute>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/callback' element={<Callback />} />
        <Route path='/signup' element={<Signup />} />
        <Route
          path='/invitation'
          element={
            <Invitation
              invitation={params.get('invitation')}
              organization={params.get('organization')}
              organization_name={params.get('organization_name')}
            />
          }
        />
        <Route path='/invitationaccepted' element={<InvitationAccepted />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
    </>
  );
}
