import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import InvoiceDetailSummary from '../../components/invoices/InvoiceDetailSummary';
import InvoiceSummary from '../../components/invoices/InvoiceSummary';
import SiteWarning from '../../components/invoices/sites/SiteWarning';
import ConfigContext from '../../context/configContext';

export default function InvoiceDetail() {
  const { id, siteId } = useParams();
  const { showResellerDownloads } = useContext(ConfigContext);

  return (
    <>
      <div>
        {siteId && <SiteWarning siteId={siteId} />}
        <h3 className='text-2xl text-center mb-5'>Invoice Detail</h3>

        <InvoiceSummary
          invoiceID={id}
          showDownload
          showResellerDownloads={showResellerDownloads}
          siteID={siteId}
        />
        <InvoiceDetailSummary invoiceID={id} siteID={siteId} />
      </div>
    </>
  );
}
