import InvoiceSummary from '../../components/invoices/InvoiceSummary';
import PastInvoiceSummary from '../../components/invoices/PastInvoiceSummary';
import { useParams } from 'react-router-dom';
import SiteWarning from '../../components/invoices/sites/SiteWarning';

export default function MyInvoices() {
  const { siteId } = useParams();

  return (
    <div>
      {siteId && <SiteWarning siteId={siteId} />}
      <h4 className='text-2xl text-center mb-5'>Invoices</h4>

      <div className='flex flex-col justify-center gap-5'>
        <InvoiceSummary showView siteID={siteId} />
      </div>
      <PastInvoiceSummary siteID={siteId} />
    </div>
  );
}
