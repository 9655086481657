import { DownloadIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import Modal from '../../utils/Modal';

const RateCardDownload = ({ invoice }) => {
  const { downloadRateZipFile, error, errorDate } = usePRDWebServices();
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (error) {
      setShowErrorDialog(true);
    }
  }, [errorDate, error]);

  const close = () => {
    setShowErrorDialog(false);
  };

  return (
    <>
      {showErrorDialog && (
        <Modal headerText='Error Occured' close={close}>
          {error}
        </Modal>
      )}
      <button
        className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200 w-full'
        onClick={() =>
          invoice
            ? downloadRateZipFile(invoice.invoiceId)
            : downloadRateZipFile()
        }
      >
        <div className='flex flex-row items-center gap-2'>
          <DownloadIcon className='h-7 w-7' />
          <span className='flex-1'>Download Rate Card</span>
        </div>
      </button>
    </>
  );
};

export default RateCardDownload;
