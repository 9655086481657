import { useEffect } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';

export default function Contact() {
  const { isAuthenticated } = useAuth0();
  const { getProvider, data: provider } = usePRDWebServices();

  useEffect(() => {
    if (isAuthenticated && !provider) {
      getProvider();
    }
  }, [provider, getProvider, isAuthenticated]);

  return (
    <div>
      <h4 className='text-4xl text-center'>Contact Information</h4>
      {provider && (
        <div className='flex items-center  justify-center '>
          <div className='border-2 border-prd-purple p-4 mt-4 rounded-xl shadow-xl'>
            <p className='font-bold mb-2'>Address</p>
            <p>{provider.AddressLine1}</p>
            <p>{provider.AddressLine2}</p>
            <p>{provider.AddressLine3}</p>
            <p>{provider.City}</p>
            <p>{provider.County}</p>
            <p>{provider.PostCode}</p>
            <div className='grid grid-cols-2 my-2'>
              <p className='font-bold'>Website:</p>
              <p>{provider.Webaddress}</p>
              <p className='font-bold'>Email: </p>
              <p>{provider.Emailaddress}</p>
              <p className='font-bold'>Telephone:</p>
              <p>{provider.Telephonenumber}</p>
              {provider.Faxnumber && (
                <>
                  <p className='font-bold'>Fax: </p>
                  <p>{provider.Faxnumber}</p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
