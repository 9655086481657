import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import ConfigContext from '../../context/configContext';
import OrgNotFound from './OrgNotFound';

const CheckImage = ({ src, alt, width }) => {
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  return error ? null : (
    <img src={src} alt={alt} width={width} onError={onError} />
  );
};

export default function Login() {
  const { loginWithRedirect } = useAuth0();
  const { orgInfo, testEnvironment } = useContext(ConfigContext);
  const [params] = useSearchParams();
  const appUrl = testEnvironment
    ? process.env.REACT_APP_APP_TESTURL
    : process.env.REACT_APP_APP_URL;

  // Set the theme for the organization (prior to Auth0)
  useEffect(() => {
    if (orgInfo && orgInfo !== 'Not Found') {
      document.body.classList.add('default');
      document.body.classList.add(orgInfo.Theme.toLowerCase());
    }
  }, [orgInfo]);

  if (params.get('invitation')) {
    return (
      <Navigate
        to={'/invitation'}
        state={{
          invitation: params.get('invitation'),
          organization: params.get('organization'),
          organization_name: params.get('organization_name')
        }}
        replace={true}
      />
    );
  }
  if (params.get('organization_name')) {
    window.location.replace(
      `https://${params.get('organization_name')}.${appUrl}`
    );
  }

  if (!orgInfo) {
    return <LoadingSpinner />;
  }

  if (orgInfo === 'Not Found') {
    //TODO replace this with a nice cover page or redirect to intelligentbilling.com
    return <OrgNotFound />;
  }

  return (
    <div className='login h-screen'>
      <div className='flex h-screen items-center justify-center md:justify-start md:ml-96'>
        <div className='flex flex-col justify-center items-center gap-10'>
          <div className='flex flex-col justify-center items-center gap-2'>
            <h2 className='text-3xl font-bold text-login-text-color'>
              {orgInfo.CompanyName}
            </h2>
            <h2 className='text-3xl font-bold text-login-text-color'>
              Billing Portal
            </h2>
          </div>
          <img
            src={`../../images/logos/${orgInfo.EUPURL}-login-logo.png`}
            width='350px'
            alt='Login Logo'
          />
          <button
            // className='rounded-full bg-prd-purple text-button-text-color hover:text-button-text-alternate-color p-3 hover:bg-prd-md-purple w-36 md:w-96 font-bold text-3xl duration-200'
            className='btn bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple btn-lg w-96 outline-prd-purple'
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  organization: orgInfo.OrgID,
                  redirect_uri: `https://${orgInfo.EUPURL}.${appUrl}/callback`
                }
              })
            }
          >
            Log In
          </button>
          <CheckImage
            src={`../../images/logos/${orgInfo.EUPURL}-extra-logo.png`}
            width='600px'
          />
        </div>
      </div>
    </div>
  );
}
