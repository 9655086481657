import React, { useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import ConfigContext from '../../context/configContext';
import Modal from '../../utils/Modal';
import { downloadCSVfromJSON } from '../../utils/Utils';
import {
  DocumentTextIcon,
  PhoneIcon,
  ClipboardIcon
} from '@heroicons/react/outline';
import RateCardDownload from './RateCardDownload';

const InvoiceDownloads = ({ invoice }) => {
  const { downloadFile, error, errorDate } = usePRDWebServices();
  const { getCDRs, data: CDRs } = usePRDWebServices();
  const {
    useSDRFile,
    useCDRFile,
    CDRExtensionOverride,
    SDRExtensionOverride,
    showDownloadRateCard,
    showBInvoiceDownload
  } = useContext(ConfigContext);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  //TODO - change these to config options
  const showCDRDownload = true;
  const showSDRDownload = true;

  useEffect(() => {
    if (error) {
      setShowErrorDialog(true);
    }
  }, [errorDate, error]);

  useEffect(() => {
    if (CDRs) {
      downloadCSVfromJSON(CDRs, invoice.providerInvoiceId + '_CDRs.csv');
    }
  }, [CDRs, invoice.providerInvoiceId]);

  const close = () => {
    setShowErrorDialog(false);
  };

  const downloadCDRs = () => {
    getCDRs(invoice.invoiceId);
  };

  return (
    <>
      {showErrorDialog && (
        <Modal headerText='Error Occured' close={close}>
          {error}
        </Modal>
      )}
      {(showCDRDownload || (showSDRDownload && useSDRFile)) && (
        <div className='flex flex-col space-y-3 items-start text-left md:border-l-2 md:pl-3 md:border-prd-purple min-w-max'>
          <h1 className='text-3xl border-b-2 pb-2 border-prd-purple w-full text-center'>
            Downloads
          </h1>

          <button
            className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 w-full'
            onClick={() => downloadFile(invoice.invoiceId, 'inv')}
          >
            <div className='flex flex-row items-center gap-2'>
              <DocumentTextIcon className='h-7 w-7' />
              <span className='flex-1'>Download Invoice</span>
            </div>
          </button>

          {showBInvoiceDownload && (
            <button
              className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 w-full'
              onClick={() => downloadFile(invoice.invoiceId, 'inv', null, true)}
            >
              <div className='flex flex-row items-center gap-2'>
                <DocumentTextIcon className='h-7 w-7' />
                <span className='flex-1'>Download Invoice Report</span>
              </div>
            </button>
          )}

          {showCDRDownload && useCDRFile && (
            <button
              className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 w-full'
              onClick={() =>
                downloadFile(invoice.invoiceId, 'cdr', CDRExtensionOverride)
              }
            >
              <div className='flex flex-row items-center gap-2'>
                <PhoneIcon className='h-7 w-7' />
                <span className='flex-1'>Download CDR</span>
              </div>
            </button>
          )}
          {showCDRDownload && !useCDRFile && (
            <button
              className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 w-full'
              onClick={() => downloadCDRs()}
            >
              <div className='flex flex-row items-center gap-2'>
                <PhoneIcon className='h-7 w-7' />
                <span className='flex-1'>Download CDR</span>
              </div>
            </button>
          )}
          {showSDRDownload && useSDRFile && (
            <button
              className='border p-2 rounded-xl border-gray-500 bg-prd-purple text-button-text-color hover:text-button-text-alternate-color hover:bg-prd-md-purple duration-200 w-full'
              onClick={() =>
                downloadFile(invoice.invoiceId, 'sdr', SDRExtensionOverride)
              }
            >
              <div className='flex flex-row items-center gap-2'>
                <ClipboardIcon className='h-7 w-7' />
                <span className='flex-1'>Download SDR</span>
              </div>
            </button>
          )}
          {showDownloadRateCard && <RateCardDownload invoice={invoice} />}
        </div>
      )}
    </>
  );
};

export default InvoiceDownloads;
