import { useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import Table from '../ui/Table';
import { useAuth0 } from '@auth0/auth0-react';
import getSymbolFromCurrency from 'currency-symbol-map';
import ConfigContext from '../../context/configContext';

export default function Services({ invoiceID, siteID }) {
  const { isAuthenticated } = useAuth0();
  const { getServices, data: services } = usePRDWebServices();
  const [dataGridServices, setDataGridServices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const { customerDetails } = useContext(ConfigContext);
  const { getSite, data: site } = usePRDWebServices();
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (isAuthenticated && !services) {
      getServices(invoiceID, 'false', siteID);
    }
    if (services) {
      const dgServices = [];
      let totalAmount = 0;

      services.forEach((service, index) => {
        const newService = { ...service, id: index };
        newService.startdateD = new Date(
          newService.startdateD
        ).toLocaleDateString();
        totalAmount += newService.localamount;
        newService.localamount =
          currencySymbol + newService.localamount.toFixed(3);
        dgServices.push(newService);
      });
      totalAmount = currencySymbol + totalAmount.toFixed(2);
      setTotalAmount(totalAmount);
      setDataGridServices(dgServices);
    }
  }, [
    services,
    currencySymbol,
    getServices,
    isAuthenticated,
    invoiceID,
    siteID
  ]);

  useEffect(() => {
    if (customerDetails?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(customerDetails.currency));
    }
    if (!site && siteID) {
      getSite(siteID);
    }
    if (site) {
      setCurrencySymbol(getSymbolFromCurrency(site.currency));
    }
  }, [siteID, site, customerDetails, getSite]);

  const tableColumns = [
    { Header: 'Date', accessor: 'startdateD' },
    { Header: 'Telephone', accessor: 'teleNum' },
    { Header: 'Product', accessor: 'description' },
    { Header: 'Units', accessor: 'units' },
    { Header: 'Amount', accessor: 'localamount' }
  ];

  return (
    <div>
      {dataGridServices && (
        <Table
          columns={tableColumns}
          data={dataGridServices}
          totalAmount={totalAmount}
          showSearch
        />
      )}
    </div>
  );
}
