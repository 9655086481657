import { useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';
import ConfigContext from '../../context/configContext';
import Table from '../ui/Table';

export default function Services({ siteID }) {
  const { getProducts, data: products } = usePRDWebServices();
  const { isAuthenticated } = useAuth0();
  const { displayActiveNumbers } = useContext(ConfigContext);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      getProducts(displayActiveNumbers ? 1 : 0, siteID);
    }
  }, [isAuthenticated, displayActiveNumbers, getProducts, siteID]);

  useEffect(() => {
    setTableColumns();
  }, []);

  const setTableColumns = () => {
    let tableColumns = [];
    tableColumns = [
      {
        Header: () => <div>Service</div>,
        id: 'telephone',
        accessor: 'telephone',
        Cell: ({ row }) => <div>{row.original.telephone}</div>
      },
      {
        Header: () => <div>Description</div>,
        id: 'description',
        accessor: 'description',
        Cell: ({ row }) => (
          <div className='text-left'>{row.original.description}</div>
        )
      }
    ];

    setColumns(tableColumns);
  };

  return (
    <div>
      {products && <Table columns={columns} data={products} showSearch />}
    </div>
  );
}
