import { useState } from 'react';
import { useEffect } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';
import Table from '../ui/Table';
import LoadingSpinner from '../ui/LoadingSpinner';

const UnbilledUsage = ({ searchNumber }) => {
  const { isAuthenticated } = useAuth0();
  const { getUnbilled, data: unbilled } = usePRDWebServices();
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState(searchNumber || '');

  useEffect(() => {
    if (isAuthenticated && !unbilled) {
      getUnbilled();
    }
    setTableColumns();
  }, [isAuthenticated, unbilled, getUnbilled]);

  const setTableColumns = () => {
    let tableColumns = [];

    tableColumns = [
      { Header: 'Number', accessor: 'telephonenumber' },
      { Header: 'Cost Centre', accessor: 'CostCentre1' },
      { Header: 'Type', accessor: 'CallTypeD' },
      {
        Header: 'Calls',
        Cell: ({ row }) => (
          <div>{new Intl.NumberFormat().format(row.original.calls)}</div>
        )
      },
      {
        Header: 'Duration (Minutes or MB)',
        Cell: ({ row }) =>
          row.original.CallTypeD === 'Data' ? (
            <div>
              {(row.original.duration_seconds_bytes / 1024 / 1024).toFixed(2)}{' '}
              (MB)
            </div>
          ) : row.original.CallTypeD === 'Voice' ? (
            <div>
              {new Date(row.original.duration_seconds_bytes * 1000)
                .toISOString()
                .slice(11, 19)}
            </div>
          ) : (
            <div>{row.original.duration_seconds_bytes}</div>
          )
      },
      {
        Header: 'Amount',
        Cell: ({ row }) => <div>£{row.original.amount.toFixed(2)}</div>
      }
    ];

    setColumns(tableColumns);
  };

  return (
    <div className=' p-2 rounded-xl max-w-md lg:max-w-3xl md:max-w-xl m-auto flex-col justify-center items-center'>
      <div className='flex-col justify-center text-center'>
        <h1 className='text-center text-3xl mb-2'>Current Usage</h1>
        <input
          className='rounded-lg m-auto w-60 my-2 p-2 border border-prd-purple'
          placeholder='Search Number'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div>
          {unbilled ? (
            <Table
              columns={columns}
              data={unbilled.filter((usage) => {
                if (search === '') {
                  return usage;
                } else if (usage.telephonenumber.includes(search)) {
                  return usage;
                }
                return null;
              })}
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default UnbilledUsage;
