import { useContext } from 'react';
import ConfigContext from '../context/configContext';

export default function AccountDetails() {
  const { customerDetails } = useContext(ConfigContext);

  return (
    <>
      {customerDetails && (
        <div className='flex flex-col border-2 border-prd-purple p-5 rounded-2xl bg-white h-full '>
          <h6 className='text-xl font-bold mb-2 uppercase'>Account Details</h6>
          <div className='grid grid-cols-2 gap-2'>
            <p className='font-bold'>Account Number</p>
            <p>{customerDetails.accountNumber}</p>
            <p className='font-bold'>Customer Name</p>
            <p>{customerDetails.customerName}</p>
            <p className='font-bold'>Address</p>
            <div>
              <p>{customerDetails.addressLine1}</p>
              <p>{customerDetails.addressLine2}</p>
              <p>{customerDetails.addressLine3}</p>
              <p>{customerDetails.addressLine4}</p>
              <p>{customerDetails.postCode}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
