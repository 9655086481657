import { useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';
import InvoiceItems from '../invoiceItems/InvoiceItems';
import Services from '../invoiceItems/Services';
import { Tab } from '@headlessui/react';
import { classNames } from '../../utils/Utils';
import getSymbolFromCurrency from 'currency-symbol-map';
import ConfigContext from '../../context/configContext';

export default function InvoiceDetailSummary({ invoiceID, siteID }) {
  const { isAuthenticated } = useAuth0();
  const { getTransactionSummary, data: transactionSummary } =
    usePRDWebServices();
  const { customerDetails } = useContext(ConfigContext);
  const { getSite, data: site } = usePRDWebServices();
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (isAuthenticated) {
      if (!transactionSummary) {
        getTransactionSummary(invoiceID, false, 'langen', siteID);
      }
      if (siteID) {
        getSite(siteID);
      }
    }
  }, [
    transactionSummary,
    siteID,
    getSite,
    getTransactionSummary,
    invoiceID,
    isAuthenticated
  ]);

  useEffect(() => {
    if (customerDetails?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(customerDetails.currency));
    }
    if (site) {
      setCurrencySymbol(getSymbolFromCurrency(site.currency));
    }
  }, [site, customerDetails]);

  return (
    <div className='w-full flex flex-col xl:flex-row xl:space-x-5 space-y-2 xl:space-y-0 '>
      {transactionSummary && (
        <Tab.Group vertical>
          <Tab.List className='flex xl:flex-col h-full space-x-1 xl:space-y-3 rounded-xl bg-prd-md-purple p-2 xl:pt-5 border-prd-purple border-2'>
            {transactionSummary.map((trans) => (
              <Tab
                key={trans.Calltype}
                className={({ selected }) =>
                  classNames(
                    'w-[175px] rounded-lg py-2.5 text-sm font-medium leading-5 text-prd-dk-green',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-prd-purple focus:outline-none ',
                    selected
                      ? 'bg-white shadow'
                      : 'text-blue-100 hover:bg-prd-md-purple/[0.5] hover:text-white'
                  )
                }
              >
                {trans.Calltype === 0 ? (
                  <div className='flex flex-col'>
                    <div className='font-bold'>{trans.type}</div>
                    <div className='text-xs'>
                      Services costing {currencySymbol}
                      {trans.TotalAmount}
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-col'>
                    <div className='font-bold'>{trans.type}</div>
                    <div className='text-xs'>
                      {trans.callsD} costing {currencySymbol}
                      {trans.TotalAmount}
                    </div>
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className='w-full'>
            {transactionSummary.map((trans) => (
              <Tab.Panel key={trans.Calltype}>
                {trans.Calltype === 0 ? (
                  <Services invoiceID={trans.Invoice} siteID={siteID} />
                ) : (
                  <InvoiceItems
                    invoiceID={trans.Invoice}
                    callType={trans.Calltype}
                    siteID={siteID}
                  />
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
}
