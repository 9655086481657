import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ConfigContext from '../../context/configContext';
import { Navigate } from 'react-router-dom';

import LoadingSpinner from '../../components/ui/LoadingSpinner';

const Callback = () => {
  const { isAuthenticated } = useAuth0();
  const { orgInfo, numAuthorisedCustomers } = useContext(ConfigContext);
  const [numCustomers, setNumCustomers] = useState(0);

  //Set the Theme for the organisation once we have returned from Auth0
  useEffect(() => {
    if (orgInfo) {
      document.body.classList.add('default');
      document.body.classList.add(orgInfo.Theme.toLowerCase());
    }
  }, [orgInfo]);

  useEffect(() => {
    const getNumCustomers = async () => {
      const numberOfCustomers = await numAuthorisedCustomers();
      setNumCustomers(numberOfCustomers);
    };

    if (isAuthenticated) {
      getNumCustomers();
    }
  }, [isAuthenticated, numAuthorisedCustomers]);

  if (isAuthenticated === false) {
    return <h1>Logging In</h1>;
  }

  return (
    <>
      {numCustomers === 1 && isAuthenticated && (
        <Navigate to='/' replace={true} />
      )}
      {numCustomers > 1 && isAuthenticated && (
        <Navigate to='/SelectAccount' replace={true} />
      )}
      <LoadingSpinner />
    </>
  );
};

export default Callback;
