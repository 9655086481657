import React, { useContext } from 'react';
import ConfigContext from '../../../context/configContext';
import Menu from './Menu';
import AccountMenu from './AccountMenu';

const Navbar2 = () => {
  const { orgInfo, setDrawerOpen, drawerOpen } = useContext(ConfigContext);
  return (
    <div className='fixed w-full navbar bg-white text-prd-purple border-b-2 border-blue-600'>
      <div className='navbar-start'>
        <label
          htmlFor='app-drawer'
          className='btn btn-square btn-ghost lg:hidden'
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            className='inline-block w-6 h-6 stroke-current'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h16M4 18h16'
            ></path>
          </svg>
        </label>
        <img
          src={`../../images/logos/${orgInfo.EUPURL}-navbar-logo.png`}
          className='h-12 lg:flex hidden m-2'
          alt='Navbar Logo'
        />
      </div>
      <div className='navbar-center '>
        <ul className='menu menu-horizontal hidden lg:flex text-xl'>
          <Menu />
        </ul>
        <img
          src={`../../images/logos/${orgInfo.EUPURL}-navbar-logo.png`}
          className='h-12  lg:hidden'
          alt='Navbar Logo'
        />
      </div>
      <div className='navbar-end hidden lg:flex'>
        <AccountMenu />
      </div>
    </div>
  );
};

export default Navbar2;
