import { useParams } from 'react-router-dom';
import Services from '../../components/customerProducts/Services';

import SiteWarning from '../../components/invoices/sites/SiteWarning';

export default function MyServices() {
  const { siteId } = useParams();

  return (
    <>
      {siteId && <SiteWarning siteId={siteId} />}
      <h1 className='text-2xl text-center mb-4'>My Services</h1>
      <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center md:items-start'>
        <Services siteID={siteId} />
      </div>
    </>
  );
}
