import { useAuth0 } from '@auth0/auth0-react';
import { LogoutIcon, RefreshIcon } from '@heroicons/react/solid';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../../context/configContext';

const AccountMenu = () => {
  const { user, logout } = useAuth0();
  const { orgInfo, testEnvironment, setCustomerID, numAuthorisedCustomers } =
    useContext(ConfigContext);
  const appUrl = testEnvironment
    ? process.env.REACT_APP_APP_TESTURL
    : process.env.REACT_APP_APP_URL;

  return (
    <div className='dropdown dropdown-end'>
      <label tabIndex={0} className='btn btn-ghost btn-circle avatar'>
        <div className='w-10 rounded-full'>
          <img alt='avatar' src={user.picture} />
        </div>
      </label>
      <div className='p-2 shadow-xl menu dropdown-content bg-base-100 rounded-box w-64 border-prd-purple border-2'>
        <p className='text-bold text-sm text-center'>{user.email}</p>
        <ul>
          {numAuthorisedCustomers() > 1 && (
            <li>
              <Link to={'/SelectAccount'} onClick={() => setCustomerID(null)}>
                <RefreshIcon className='w-5 h-5' />
                Change Account
              </Link>
            </li>
          )}
          <li>
            <button
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: `https://${orgInfo.EUPURL}.${appUrl}`
                  }
                })
              }
            >
              <LogoutIcon className='w-5 h-5' />
              log Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountMenu;
