import PastInvoices from '../InvoiceHistory/PastInvoices';
import PastInvoicesGraph from '../InvoiceHistory/PastInvoicesGraph';

export default function PastInvoiceSummary({ siteID }) {
  return (
    <div className='grid lg:grid-cols-2 grid-cols-1 gap-2'>
      <div className='lg:block hidden'>
        <PastInvoicesGraph siteID={siteID} />
      </div>
      <div className=''>
        <PastInvoices siteID={siteID} />
      </div>
    </div>
  );
}
