import React from 'react';

const Error = ({ errorMsg, errorDescription }) => {
  return (
    <div className='bg-red-300 border-red-700 m-6 border-4 rounded-xl p-4'>
      <h1 className='font-bold text-xl mb-3'>Error Occured:</h1>
      <pre className='font-bold'>{errorMsg}</pre>
      <pre>{errorDescription}</pre>
    </div>
  );
};

export default Error;
