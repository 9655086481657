import React, { useState } from "react";
import { useEffect } from "react";
import { useDWSWebServices } from "../../../hooks/useDWSWebServices";
import LoadingSpinner from "../../ui/LoadingSpinner";

const DWSSimSwap = ({ mobile, network }) => {
  const { activateSim, data: simData } = useDWSWebServices();
  const [serialNumber, setSerialNumber] = useState("");
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setWaiting(true);

    activateSim(
      mobile,
      network === "Vodafone" ? "89441" + serialNumber : "894411" + serialNumber,
      "nano"
    );
  };

  useEffect(() => {
    if (simData !== null) {
      setWaiting(false);
    }
  }, [simData]);

  if (waiting) {
    return <LoadingSpinner />;
  }

  if (simData !== null) {
    if (simData.status === "0") {
      if (!simData.valid) {
        return (
          <div>
            <h1 className='font-bold'>An Error Occurred</h1>
            <p>Sim number supplied is not value</p>
          </div>
        );
      }
      return (
        <div>Sim Swap Requested (this takes around 5 minutes to complete)</div>
      );
    } else {
      return (
        <div>
          <h1 className='font-bold'>An Error Occurred</h1>
          <p>Status Code: {simData.status}</p>
          <p>Error: {simData.error.errorText}</p>
        </div>
      );
    }
  }

  return (
    <>
      <div>New Sim Serial</div>{" "}
      <div className='flex flex-col justify-items-center items-center'>
        <div className='flex gap-2 justify-center items-center'>
          <div className=' text-gray-400'>
            {network === "Vodafone" ? <p>89441</p> : <p>894411</p>}
          </div>
          <input
            type='text'
            className='rounded-lg w-72'
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
        </div>

        <button
          type='button'
          className='border mt-3 p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200 w-fit'
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default DWSSimSwap;
