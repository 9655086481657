import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import { useAuth0 } from '@auth0/auth0-react';
import getSymbolFromCurrency from 'currency-symbol-map';
import ConfigContext from '../../context/configContext';

export default function PastInvoices({ siteID }) {
  const { isAuthenticated } = useAuth0();
  const { getTopInvoices, data: topInvoices } = usePRDWebServices();
  const [topInvoicesNo, setTopInvoicesNo] = useState(6);
  const { customerDetails } = useContext(ConfigContext);
  const { getSite, data: site } = usePRDWebServices();
  const [currencySymbol, setCurrencySymbol] = useState('£');

  const handleMoreInvoices = () => {
    if (topInvoicesNo === 6) {
      setTopInvoicesNo(100);
    } else {
      setTopInvoicesNo(6);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getTopInvoices(topInvoicesNo, siteID);
      if (siteID) {
        getSite(siteID);
      }
    }
  }, [isAuthenticated, topInvoicesNo, siteID, getSite, getTopInvoices]);

  useEffect(() => {
    if (customerDetails?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(customerDetails.currency));
    }
    if (site) {
      setCurrencySymbol(getSymbolFromCurrency(site.currency));
    }
  }, [site, customerDetails]);

  return (
    <div className='border-2 border-prd-purple p-2 rounded-xl'>
      <div className='grid grid-cols-2 gap-4 md:grid-cols-3 mb-5 hover'>
        {topInvoices &&
          topInvoices.map((invoice) => (
            <Link
              underline='none'
              to={
                siteID
                  ? `/Invoices/sites/${siteID}/${invoice.invoiceId}`
                  : `/Invoices/${invoice.invoiceId}`
              }
              className='border-2 rounded-xl border-prd-purple shadow-xl bg-prd-purple hover:scale-105 duration-200'
              key={invoice.invoiceId}
            >
              <div className='flex flex-col justify-center text-center'>
                <div className='relative left-0 top-0 overflow-hidden  '>
                  <h5 className='text-xl text-white'>
                    {invoice.providerInvoiceId}
                  </h5>
                </div>
                <div className='bg-white h-[100px] p-2 rounded-b-lg'>
                  <h6>{new Date(invoice.dueDateD).toLocaleDateString()}</h6>
                  <h6>
                    {currencySymbol}
                    {invoice.totalAmountWithVAT.toFixed(2)}
                  </h6>
                  {invoice.invoicePaid && (
                    <p className='bg-green-400 rounded-full border-green-600 border mt-2'>
                      Paid
                    </p>
                  )}
                </div>
              </div>
            </Link>
          ))}
      </div>
      <button
        className='rounded-full bg-prd-purple text-button-text-color hover:text-button-text-alternate-color p-3 hover:bg-prd-md-purple duration-200'
        onClick={handleMoreInvoices}
      >
        {topInvoicesNo === 6 ? 'Show more invoices' : 'Show fewer invoices'}
      </button>
    </div>
  );
}
