import { useContext } from 'react';
import AccountDetails from '../../components/AccountDetails';
import InvoiceSummary from '../../components/invoices/InvoiceSummary';
import ConfigContext from '../../context/configContext';

export default function Homepage() {
  const { orgInfo } = useContext(ConfigContext);

  return (
    <div className='flex flex-col justify-around max-w-6xl mx-auto gap-8'>
      <div className='flex flex-grow gap-8 justify-between'>
        <div className='flex-grow'>
          <AccountDetails />
        </div>
        <img
          src={`/images/custom/${orgInfo.EUPURL}-cover.jpg`}
          alt=''
          className='w-1/2 max-h-80 hidden md:block rounded-xl border-2 border-prd-purple object-cover'
        />
      </div>

      <div className='w-full'>
        <InvoiceSummary showView />
      </div>
    </div>
  );
}
