import { useCallback, useContext, useEffect, useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';
import Table, { TextRight } from '../ui/Table';
import { useAuth0 } from '@auth0/auth0-react';
import getSymbolFromCurrency from 'currency-symbol-map';
import ConfigContext from '../../context/configContext';

export default function Calls({ invoiceID, callType, siteID }) {
  const { isAuthenticated } = useAuth0();
  const { getCalls, data: calls } = usePRDWebServices();
  const [dataGridCalls, setDataGridCalls] = useState(null);
  const [columns, setColumns] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const { customerDetails } = useContext(ConfigContext);
  const { getSite, data: site } = usePRDWebServices();
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (isAuthenticated && !calls) {
      getCalls(invoiceID, callType, 1, siteID);
    }
    if (calls) {
      const dgCalls = [];
      let totalAmount = 0;

      calls.map((call, index) => {
        const newCall = { ...call, id: index };
        newCall.startdateD = new Date(newCall.startdateD).toLocaleDateString();
        totalAmount += newCall.amount;
        newCall.amount = currencySymbol + newCall.amount.toFixed(3);
        newCall.bnumber = newCall.bnumber.trim();
        dgCalls.push(newCall);
      });
      setDataGridCalls(dgCalls);
      totalAmount = currencySymbol + totalAmount.toFixed(2);
      setTotalAmount(totalAmount);
    }
  }, [
    isAuthenticated,
    calls,
    site,
    siteID,
    callType,
    currencySymbol,
    getCalls,
    invoiceID
  ]);

  useEffect(() => {
    if (customerDetails?.currency) {
      setCurrencySymbol(getSymbolFromCurrency(customerDetails.currency));
    }
    if (site) {
      setCurrencySymbol(getSymbolFromCurrency(site.currency));
    }
  }, [site, customerDetails]);

  const setTableColumns = useCallback(() => {
    let tableColumns = [];

    tableColumns = [
      { Header: 'Telephone / CLI', accessor: 'anumber' },
      { Header: 'Dialled Number', accessor: 'bnumber' },
      { Header: 'Date', accessor: 'startdateD' },
      { Header: 'Time', accessor: 'starttimeD' },
      { Header: 'Area', accessor: 'area' }
    ];

    if (callType === 12 || callType === 13) {
      tableColumns.push({ Header: 'Events', accessor: 'billableduration' });
    } else if (callType === 11) {
      tableColumns.push({ Header: 'KBs', accessor: 'billableduration' });
    } else {
      tableColumns.push({
        Header: 'Duration (seconds)',
        accessor: 'billableduration'
      });
    }
    tableColumns.push({
      Header: () => <div className='text-right'>Amount</div>,
      accessor: 'amount',
      Cell: TextRight
    });

    setColumns(tableColumns);
  }, [callType]);

  // run once
  useEffect(() => {
    setTableColumns();
    if (siteID) {
      getSite(siteID);
    }
  }, [siteID, getSite, setTableColumns]);

  return (
    <div>
      {dataGridCalls && (
        <Table
          columns={columns}
          data={dataGridCalls}
          totalAmount={totalAmount}
          showSearch
        />
      )}
    </div>
  );
}
