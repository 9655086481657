import React, { useState } from 'react';
import { usePRDWebServices } from '../../hooks/usePRDWebServices';

const InvoiceDirectDownloads = ({ invoiceID }) => {
  const { downloadZipFile } = usePRDWebServices();
  const [downloadItem, setDownloadItem] = useState('invoice');

  const handleSubmit = async (e) => {
    e.preventDefault();
    downloadZipFile(invoiceID, downloadItem);
  };

  return (
    <div>
      <h1 className='text-xl'>
        Download your Invoice Direct children for this invoice
      </h1>
      <div className='text-sm'>
        <form
          onSubmit={handleSubmit}
          className='flex max-w-sm justify-center items-center m-auto space-x-3'
        >
          <select
            value={downloadItem}
            onChange={(e) => setDownloadItem(e.target.value)}
          >
            <option value='invoice'>Invoices</option>
            <option value='cdr'>CDR Files</option>
            <option value='sdr'>SDR Files</option>
          </select>

          <button
            className='border px-5 py-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200 '
            type='submit'
            value='Submit'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default InvoiceDirectDownloads;
