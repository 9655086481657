import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDWSWebServices } from "../../../hooks/useDWSWebServices";
import { usePRDWebServices } from "../../../hooks/usePRDWebServices";
import LoadingSpinner from "../../ui/LoadingSpinner";

// username object does contain the mobile
const DWSUsernameManagementChange = ({ username, mobile, telephoneID }) => {
  const { setUsername, data: setUsernameData } = useDWSWebServices();
  const { setTelephoneMetaData } = usePRDWebServices();
  const [data, setData] = useState(username);
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setWaiting(true);
    setUsername(mobile, data);
  };

  // updates the PRD system with the cost centres
  const updateBillingSystem = () => {
    if (!data) {
      return;
    }
    //Change dataformat to PRD format
    const PRDData = {
      costCentre1: data.username,
      costCentre2: data.costCentre1,
      costCentre3: data.costCentre2,
      costCentre4: data.costCentre3,
    };
    setTelephoneMetaData(telephoneID, PRDData);
  };

  useEffect(() => {
    if (setUsernameData !== null) {
      setWaiting(false);
    }
  }, [setUsernameData]);

  if (waiting) {
    return <LoadingSpinner />;
  }

  if (setUsernameData !== null) {
    if (setUsernameData?.status !== "0") {
      return (
        <div>
          <h1 className='font-bold'>An Error Occurred</h1>
          <p>Status Code: {setUsernameData.status}</p>
          <p>Error: {setUsernameData.error.errorText}</p>
        </div>
      );
    } else {
      updateBillingSystem();
      return <p>Updated, reference number: {setUsernameData.caseNumber}</p>;
    }
  }

  return (
    <form>
      <div className='flex flex-col justify-center items-center'>
        <div className='grid grid-cols-2 gap-1 w-full'>
          <p>Username</p>
          <input
            type='text'
            value={data.username}
            className='rounded-lg'
            onChange={(e) => setData({ ...data, username: e.target.value })}
          />
          <p>Cost Centre 1</p>
          <input
            type='text'
            value={data.costCentre1}
            className='rounded-lg'
            onChange={(e) => setData({ ...data, costCentre1: e.target.value })}
          />
          <p>Cost Centre 2</p>
          <input
            type='text'
            value={data.costCentre2}
            className='rounded-lg'
            onChange={(e) => setData({ ...data, costCentre2: e.target.value })}
          />
          <p>Cost Centre 3</p>
          <input
            type='text'
            value={data.costCentre3}
            className='rounded-lg'
            onChange={(e) => setData({ ...data, costCentre3: e.target.value })}
          />
        </div>
        <button
          type='button'
          className='border mt-3 p-2 rounded-xl border-gray-500 bg-prd-purple text-white hover:bg-prd-md-purple duration-200 w-fit'
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default DWSUsernameManagementChange;
