import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter
} from 'react-table';
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon
} from '@heroicons/react/solid';
import { PageButton } from './Button';
import { classNames } from '../../utils/Utils';
import { useState } from 'react';

export function TextRight({ value }) {
  return (
    <span className={classNames('text-right flex justify-end')}>{value}</span>
  );
}

export function TextLeft({ value }) {
  return <span className={classNames('flex justify-start')}>{value}</span>;
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span className='p-3'>
      Search:{' '}
      <input
        className='p-2 mb-2'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </span>
  );
}

const Table = ({ columns, data, totalAmount, showSearch = false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  return (
    <div className='flex flex-col'>
      <div className=''>
        {showSearch && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <div className='shadow-lg overflow-hidden border-prd-purple border-2 rounded-lg'>
          <table
            {...getTableProps()}
            border='1'
            className='divide-y divide-gray-200 min-w-full '
          >
            <thead className='bg-gray-50'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope='col'
                      className='group md:px-6 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider'
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className='bg-white divide-y divide-200'
            >
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className='md:px-6 text-sm text-center py-1 whitespace-no-wrap'
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {totalAmount && (
        <div className='text-right text-lg font-bold mt-4'>
          Total: {totalAmount}
        </div>
      )}

      <div className='flex flex-col md:flex-row justify-between items-center'>
        <div className='flex justify-center items-center mt-2 gap-2'>
          <div className='flex items-center gap-5 '>
            <div className='text-right'>
              Page{' '}
              <strong>
                {state.pageIndex + 1} of {pageOptions.length}
              </strong>
            </div>
            <select
              className='rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
              value={state.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>
            <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
              <PageButton
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                className='rounded-l-md'
              >
                <ChevronDoubleLeftIcon className='h-5 w-5' />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <ChevronLeftIcon className='h-5 w-5' />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <ChevronRightIcon className='h-5 w-5' />
              </PageButton>
              <PageButton
                className='rounded-r-md'
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <ChevronDoubleRightIcon className='h-5 w-5' />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
