import { Outlet } from 'react-router-dom';
import Footer from '../components/ui/Footer';
import Navbar2 from '../components/ui/navBar/Navbar2';
import Drawer from '../components/ui/Drawer';

export const SharedLayout = () => {
  return (
    <div className='flex flex-col min-h-screen bg-portal-background-color'>
      <div className='drawer h-auto'>
        <input id='app-drawer' type='checkbox' className='drawer-toggle' />
        <div className='drawer-content  '>
          <Navbar2 />
          <div className='mt-28 mb-5 lg:p-5 text-gray-700 mx-auto lg:w-3/4 w-full bg-white border-2 border-prd-purple rounded-2xl'>
            <Outlet />
          </div>
        </div>
        <Drawer />
      </div>
      <Footer />
    </div>
  );
};
