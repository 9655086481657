import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Error from '../../components/ui/Error';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import ConfigContext from '../../context/configContext';

export const Invitation = () => {
  const location = useLocation();
  const { invitation, organization, organization_name } = location.state || {};
  const [error, setError] = useState();
  const { testEnvironment } = useContext(ConfigContext);

  useEffect(() => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const appUrl = testEnvironment
      ? process.env.REACT_APP_APP_TESTURL
      : process.env.REACT_APP_APP_URL;

    if (invitation && organization && organization_name) {
      let url = `https://${domain}/authorize?invitation=${invitation}&organization=${organization}&organization_name=${organization_name}&`;
      url =
        url +
        `redirect_uri=https://${organization_name}.${appUrl}/invitationaccepted&response_type=code&client_id=${clientId}`;

      window.location.href = url;

      // } else window.location.href = "https://intelligentbilling.com";
    } else {
      setError('Invalid Invitation');
    }
  }, [invitation, organization, organization_name, testEnvironment]);

  return <>{error ? <Error errorMsg={error} /> : <LoadingSpinner />}</>;
};
