import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ConfigContext from '../../context/configContext';
import { useState } from 'react';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import Error from '../../components/ui/Error';

const InvitationAccepted = () => {
  const { orgInfo, testEnvironment } = useContext(ConfigContext);
  const { loginWithRedirect } = useAuth0();

  const [params] = useSearchParams();
  const [error, setError] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const appUrl = testEnvironment
    ? process.env.REACT_APP_APP_TESTURL
    : process.env.REACT_APP_APP_URL;

  useEffect(() => {
    (async () => {
      if (params.get('error')) {
        setError(params.get('error'));
        setErrorDescription(params.get('error_description'));
      } else if (orgInfo) {
        loginWithRedirect({
          authorizationParams: {
            organization: orgInfo.OrgID,
            redirect_uri: `https://${orgInfo.EUPURL}.${appUrl}/callback`
          }
        });
      }
    })();
  }, [orgInfo, appUrl, loginWithRedirect, params]);

  return (
    <>
      {error ? (
        <Error errorMsg={error} errorDescription={errorDescription} />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default InvitationAccepted;
