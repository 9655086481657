import { useState } from "react";
import { useEffect } from "react";
import { useDWSWebServices } from "../../../hooks/useDWSWebServices";
import LoadingSpinner from "../../ui/LoadingSpinner";

const DWSStolenBar = ({ mobile }) => {
  const { getStolenBar, data: stolenBarResult } = useDWSWebServices();
  const { setStolenBar, data: setStolenBarResult } = useDWSWebServices();
  const [currentStolenBar, setCurrentStolenBar] = useState(null);
  const [stolenBarName, setStolenBarName] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (stolenBarResult === null) {
      getStolenBar(mobile);
      setLoading(true);
    } else {
      setCurrentStolenBar(stolenBarResult.stolenBar);
      setStolenBarName(stolenBarResult.stolenBarName);
      setLoading(false);
    }
  }, [stolenBarResult, getStolenBar, mobile]);

  useEffect(() => {
    if (setStolenBarResult !== null) {
      setLoading(false);
    }
  }, [setStolenBarResult]);

  const saveStolenBar = () => {
    setStolenBar(mobile, currentStolenBar, stolenBarName);
    setLoading(true);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (stolenBarResult !== null) {
    if (stolenBarResult.status !== "0") {
      return (
        <div>
          <h1 className='font-bold'>An Error Occurred</h1>
          <p>Status Code: {stolenBarResult.status}</p>
          <p>Error: {stolenBarResult.error.errorText}</p>
        </div>
      );
    }
  }

  if (setStolenBarResult !== null) {
    if (setStolenBarResult.status !== "0") {
      return (
        <div>
          <h1 className='font-bold'>An Error Occurred</h1>
          <p>Status Code: {setStolenBarResult.status}</p>
          <p>Error: {setStolenBarResult.error.errorText}</p>
        </div>
      );
    } else {
      return (
        <div>
          {currentStolenBar ? (
            <h1 className='font-bold'>Stolen Bar Add Requested</h1>
          ) : (
            <h1 className='font-bold'>Stolen Bar Removal Requested</h1>
          )}
          <p>Status Code: {setStolenBarResult.caseNumber}</p>
          <p className='text-sm mt-3'>
            NOTE: it may take a few minutes for the request to be made active
            and shown here
          </p>
        </div>
      );
    }
  }

  return (
    <>
      {currentStolenBar !== null && (
        <div className='flex flex-col justify-center text-center'>
          <div className='flex items-center justify-center w-full mb-12'>
            <label
              htmlFor='stolenToggle'
              className='flex items-center cursor-pointer'
            >
              <div className='relative'>
                <input
                  type='checkbox'
                  checked={currentStolenBar}
                  onChange={() => setCurrentStolenBar(!currentStolenBar)}
                  id='stolenToggle'
                  className='sr-only'
                />
                <div className='block bg-gray-600 w-14 h-8 rounded-full'></div>
                <div className='dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition'></div>
              </div>

              <div className='ml-3 text-gray-700 font-medium'>Stolen Bar</div>
            </label>
          </div>

          <button
            type='button'
            className={`border mt-3 p-2 rounded-xl border-gray-500 ${
              currentStolenBar === stolenBarResult.stolenBar
                ? "bg-gray-400"
                : "bg-prd-purple hover:bg-prd-md-purple"
            } text-white  duration-200`}
            onClick={() => saveStolenBar()}
            disabled={
              currentStolenBar === stolenBarResult.stolenBar ? "disable" : ""
            }
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default DWSStolenBar;
